import React, {useState} from 'react';
import 'antd/dist/antd.css';
// import '../index.css';
import ImageUpload from './ImageUpload'
import {Table, InputNumber, Typography} from 'antd';

const {Title} = Typography;

const App: React.FC = (props) => {
    const [imageTags, setImageTags] = useState('')
    const [threshold, setThreshold] = useState(0.4)

    const columns = [
        {
            title: 'Tag',
            dataIndex: 'Tag',
            key: 'Tag',
            sorter: (a, b) => a.Tag.localeCompare(b.Tag),
            ellipsis: true,
        }, {
            title: 'score',
            dataIndex: 'score',
            key: 'score',
            sorter: (a, b) => a.score - b.score,
            ellipsis: true,
        }
    ]

    const setTags = (data) => {
        let tgs = []
        let idx = 0;
        for (const i in data) {
            let t = {}
            t['key'] = idx
            t['Tag'] = data[i]['tag']
            t['score'] = data[i]['score']
            tgs.push(t)
            idx += 1
        }

        setImageTags(tgs);
    }

    const onChange = (value) => {
        if (value > 0 && value <= 1) {
            setThreshold(value)
        } else {
            setThreshold(0.4)
        }
    };

    return (
        <>
            <Title level={4}>ResNet Auto-Tagging</Title>
            {props.info}
            <ImageUpload
                // urlAPI={'https://arz.ai/pic2tag?threshold=' + threshold}
                urlAPI={props.url + threshold}
                setResponse={setTags}
                onChange={setTags}
                name={'image'}
            />
            Threshold
            <InputNumber
                style={{
                    width: 200,
                }}
                defaultValue="0.4"
                min="0"
                max="1"
                step="0.1"
                onChange={onChange}
                stringMode
            />
            <Table
                columns={columns}
                dataSource={imageTags}
                pagination={{
                    pageSize: 50,
                }}
            />

        </>
    );
};

export default App;