import React, {useState} from 'react';
import 'antd/dist/antd.css';
import '../index.css';
import {InputNumber, Typography, Select, Input} from 'antd';
import type {SelectProps} from 'antd';
import ImageUpload from './ImageUpload'

const {TextArea} = Input;

const {Title} = Typography;

const App: React.FC = () => {
    const [limit, setLimit] = useState(32)
    const [pool_id, setPool_id] = useState(['0', '1'])
    const [text, setText] = useState([])
    const [loadign, setLoading] = useState(false)

    const options: SelectProps['options'] = [];
    options.push({
        label: 'Danbooru',
        value: '0',
    });
    options.push({
        label: 'Gelbooru',
        value: '1',
    });


    const {Search} = Input;

    const search = (s) => {
        setLoading(true)

        const url_id = s.split('?v=')[1].split('&')[0]
        console.log(url_id)

        const fmData = new FormData();

        fmData.append("file", s);

        // console.log(fmData)

        const requestOptions = {
            method: 'GET',
            // body: fmData,
        };

        try {
            // console.log(action)
            // fetch('http://127.0.0.1:8000/picsearch/url/', requestOptions).then(response => response.json()).then(
            fetch('https://whisper.arz.ai/yb/' + url_id, requestOptions).then(response => response.json()).then(
                (data) => {
                    setText(data)
                    setLoading(false)
                }
            )


            // const h = await response.body
            // console.log(h)


            // console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
        }
    }

    return (
        <>
            <Title level={4}>Speech to text with whisper (max 10 min)</Title>
            <ImageUpload
                urlAPI={'https://whisper.arz.ai/file'}
                setResponse={setText}
                name={'file'}
            />
            <br/>
            By YouTube URL
            <Search placeholder="URL image"
                    loading={loadign}
                    enterButton
                    onSearch={search}
            />

            <br/>
            <br/>
            <br/>

            <Title level={5}>Language: {text['lang']}</Title>
            <Title level={5}>GPU time: {text['time']}</Title>

            <Title level={5}>Plain text</Title>
            <TextArea rows={16}
                      value={text['txt']}
            />
            <Title level={5}>SRT format</Title>
            <TextArea rows={16}
                      value={text['srt']}
            />

        </>
    );
};

export default App;