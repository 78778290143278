import React, {useState} from 'react';
import 'antd/dist/antd.css';
import '../index.css';
import {InputNumber, Typography, Select, Input} from 'antd';
import type {SelectProps} from 'antd';
import ImageUpload from './ImageUpload'

var Latex = require('react-latex');

const {TextArea} = Input;

const {Title} = Typography;

const App: React.FC = () => {
    const [text, setText] = useState({'latex': ''})

    const options: SelectProps['options'] = [];
    options.push({
        label: 'Danbooru',
        value: '0',
    });
    options.push({
        label: 'Gelbooru',
        value: '1',
    });

    return (
        <>
            <Title level={4}>LaTex OCR Math Limit</Title>
            <ImageUpload
                urlAPI={'https://arz.ai/ocr_math_limit'}
                // urlAPI={'http://127.0.0.1:8000/ocr_math_limit'}
                setResponse={setText}
                name={'image'}
            />

            <br/>
            <br/>
            <br/>

            <Title level={5}>Raw text</Title>
            <TextArea rows={2}
                      value={text['latex']}
            />
            <h3>
                <Latex>{'$' + text['latex'] + '$'}</Latex>
            </h3>

        </>
    );
};

export default App;