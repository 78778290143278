import React, {useState} from 'react';
import 'antd/dist/antd.css';
import './index.css';
import {
    AppstoreOutlined,
} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Layout, Menu} from 'antd';
import {
    AntDesignOutlined,
} from '@ant-design/icons';
import {Avatar} from 'antd';

import ImageTag from './projects/ImageTag'
import ImageBorder from './projects/ImageBorder'
import Whisper from "./projects/Whisper";
import Math from "./projects/OcrMathLimit";

const {Header, Content, Footer, Sider} = Layout;

const nav = [
    'Tag',
    'Tag_v2',
    'Search',
    'Border',
    'Math',
    'Whisper',
    // 'Login',
    // 'Logout'
]

const items: MenuProps['items'] = [
    AppstoreOutlined,
    AppstoreOutlined,
    AppstoreOutlined,
    AppstoreOutlined,
    AppstoreOutlined,
    AppstoreOutlined,
].map((icon, index) => ({
    key: nav[index],
    icon: React.createElement(icon),
    label: nav[index]
}));

const App: React.FC = () => {
    const [display, setDisplay] = useState('Tag');
    const [collapsed, setCollapsed] = useState(200);
    const onTitleClick = (data) => {
        const {key} = data;
        if (key === 'Search'){
            window.open('https://findit.moe/', '_blank', 'noopener,noreferrer')
        }else{
            setDisplay(key)
        }

    }
    const minMargin = 50

    // fetch('https://arz.ai/login' )
    // fetch('http://127.0.0.1:8000/login')
    //     .then(response => response.text())
    //     .then(data => {
    //         console.log(data)
    //     });

    return (<Layout hasSider>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}

                breakpoint="lg"
                collapsedWidth={minMargin}

                onBreakpoint={(broken) => {
                    setCollapsed(broken ? minMargin : 200)
                }}
            >
                <Avatar
                    size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,

                    }}
                    icon={<AntDesignOutlined/>}
                />

                <Menu theme="dark"
                      mode="inline"
                      defaultSelectedKeys={['1']}
                      items={items}
                      onClick={onTitleClick}
                />
            </Sider>
            <Layout className="site-layout" style={{marginLeft: collapsed}}>
                <Header className="site-layout-background" style={{padding: 0}}/>
                <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                    {display === 'Tag' && <ImageTag
                        url={'https://arz.ai/pic2tag?threshold='}
                        // url={'http://127.0.0.1:8000/pic2tag?threshold='}
                        info={'Version 1, epoch=30, images=1500000, tags=1000'}
                    />}
                    {display === 'Tag_v2' && <ImageTag
                        url={'https://arz.ai/pic2tag_v2?threshold='}
                        // url={'http://127.0.0.1:8000/pic2tag_v2?threshold='}
                        info={'Version 2, backbone weights from v1, epoch=12, images=3600000, tags=8192'}
                    />}
                    {/*{display === 'Search' && <ImageSearch/>}*/}
                    {display === 'Border' && <ImageBorder/>}
                    {display === 'Math' && <Math/>}
                    {display === 'Whisper' && <Whisper/>}
                    {/*{display === 'Login' && <Login/>}*/}
                    {/*{display === 'Logout' && <ImageBorder/>}*/}
                </Content>
                <Footer style={{textAlign: 'center'}}>arz.ai Images </Footer>
            </Layout>
        </Layout>
    );
}

export default App;