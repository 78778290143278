import React, {useState, useEffect} from 'react';
import 'antd/dist/antd.css';
import '../index.css';
import {message, Upload} from 'antd';

import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import axios from "axios";

function App(props) {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    // props.setResponse(null)
    const onChange = (info) => {
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
            setLoading(true)
        }

        if (info.file.status === 'done') {
            const images = info.file.response
            // console.log(info)
            // console.log(info)
            // props.setTimeX(info.file.headers['x-process-time'])
            props.setResponse(images)
            message.success(`${info.file.name} file uploaded successfully`);
            setLoading(false)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setLoading(false)
        }
    }

    const prv = (info) => {

    }

    const uploadImage = async options => {
        const {onSuccess, onError, file, onProgress, action} = options;

        const fmData = new FormData();
        const config = {
            // withCredentials: true,
            // headers: {'token-api-key': '368FA16CD3E4107A908B3CC1AA4208341D4AD7C03D440F4D1A6EB8709557BEF9'},
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({percent: (event.loaded / event.total) * 100});
            }
        };
        fmData.append("image", file);
        // fmData.append("limit", props.limit);
        // fmData.append("pool", props.pool);
        console.log(fmData)

        try {
            // console.log(action)
            const res = await axios.post(
                action,
                fmData,
                config
            );

            onSuccess("Ok");

            console.log(res.headers)

            props.setResponse(res.data)
            // console.log("server res: ", res);
        } catch (err) {
            // console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({err});
        }
    };

    const uploadImage_fetch = async options => {
        const {onSuccess, onError, file, onProgress, action} = options;

        const fmData = new FormData();

        fmData.append("image", file);
        try{
            fmData.append("limit", props.meta['limit']);
            fmData.append("pool", props.meta['pool']);
        }catch{

        }

        // console.log(fmData)

        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: fmData,
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({percent: (event.loaded / event.total) * 100});
            }
        };

        try {
            // console.log(action)
            const response = await fetch(action, requestOptions);
            const data = await response.json();
            onSuccess("Ok");
            const h = await response.body
            console.log(h)

            props.setResponse(data)
            // console.log("server res: ", res);
        } catch (err) {
            // console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({err});
        }
    };

    const beforeUpload = (e) => {
        props.setResponse([])
    }
    // console.log(props.meta)
    return (
        <Upload
            name={props.name}
            action={props.urlAPI}
            onChange={onChange}
            listType="picture-card"
            onPreview={prv}
            data={props.meta}
            // withCredentials={true}
            beforeUpload={beforeUpload}
            headers={{'token-api-key': '368FA16CD3E4107A908B3CC1AA4208341D4AD7C03D440F4D1A6EB8709557BEF9'}}
            // customRequest={uploadImage}
            // customRequest={uploadImage_fetch}
            // onRemove={(e)=>{setLoading(false)}}

        >
            <div>
                {loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div style={{marginTop: 8}}>Upload</div>
            </div>
        </Upload>);
}


export default App;